<template>
    <div class="article">
        <div class="title">Estad's Privacy Policy</div>
        <div class="text">
            At Estad.xyz, we are committed to protecting your privacy and ensuring the security of any personal information we collect. This privacy policy outlines how we collect, use, and protect your personal data when you use our website. <br>
            <br>
            1. Information We Collect<br>
            <br>
            We may collect the following types of information when you visit Estad.xyz:<br>
            - Personal Information:<br>
            We collect limited personal data (such as your name and email address) when you subscribe to our newsletter, submit inquiries, or sign up for premium services.<br>
            - Non-Personal Information:<br>
            We collect non-identifiable information such as your IP address, browser type, operating system, and the date and time of your visit. This data helps us understand how users interact with our website and improve the overall experience.<br>
            <br>
            2. How We Use Your Information<br>
            <br>
            We use the information we collect for the following purposes:<br>
            - To provide and maintain our services, including personalized experiences and location-based data visualizations.<br>
            - To send you updates and notifications related to our services, including new features or promotions (you can opt out at any time).<br>
            - To improve website functionality, analyze trends, and administer the website.<br>
            - To comply with legal obligations or protect the rights and safety of Estad.xyz, our users, or others.<br>
            <br>
            3. Cookies<br>
            <br>
            We use cookies and similar tracking technologies to collect data and improve your experience on Estad.xyz. Cookies are small files stored on your device that allow us to recognize you and customize your browsing experience. You can disable cookies in your browser settings, but this may limit your ability to use certain features of the website.<br>
            <br>
            4. Data Sharing and Disclosure<br>
            <br>
            We do not sell, rent, or trade your personal information to third parties. However, we may share your information with trusted third parties for the following purposes:<br>
            - To provide the services you have requested (e.g., email service providers, payment processors).<br>
            - To comply with legal requirements or respond to lawful requests (such as a subpoena or court order).<br>
            - To protect the security and integrity of our website or safeguard the rights of Estad.xyz and its users.<br>
            <br>
            5. Data Security<br>
            <br>
            We take reasonable precautions to protect your personal information from unauthorized access, disclosure, or loss. We use industry-standard security measures to safeguard the data we collect, but no system is completely secure. We encourage you to take steps to protect your own information, such as using strong passwords and maintaining privacy on your devices.<br>
            <br>
            6. Third-Party Links<br>
            <br>
            Our website may contain links to third-party websites, such as real estate listings or other external services. We are not responsible for the privacy practices or content of these external sites. We encourage you to read the privacy policies of any third-party sites you visit.<br>
            <br>
            7. Your Rights<br>
            <br>
            You have the right to access, correct, or delete any personal information we have collected from you. You may also object to the processing of your data in certain circumstances or withdraw your consent to receiving communications from us at any time.<br>
            To exercise these rights, please contact us at arleta@estad.xyz.<br>
            <br>
            8. Changes to This Privacy Policy<br>
            <br>
            We may update this privacy policy from time to time to reflect changes in our practices or applicable laws. Any changes will be posted on this page, and we encourage you to review it periodically.<br>
            <br>
            9. Contact Us<br>
            <br>
            If you have any questions or concerns about this privacy policy or how your personal information is handled, please contact us at:<br>
            <br>
            Email: arleta@estad.xyz</div>
    </div>
</template>

<script>

export default {
    name: 'ContactUs'
}

</script>


<style>

.article {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    padding: 0px 50px 100px 0px;
}

.title {
    font-weight: 800;
    font-size: 20px;
    width: 80vw;
    text-align: center;
    padding: 20px 0 20px 0;
}

.text {
    font-weight: 400;
    font-size: 12px;
    width: 80vw;
}
</style>